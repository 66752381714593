<template>
  <div class="member-detail main-wrapper">
    <div class="btn-wrapper">
      <el-button type="text" @click="closeDetail()">
        <img src="../assets/images/technology/member/member-detail/close.webp"/>
      </el-button>
    </div>
    <img v-if="detailIndex" :src="detailUrl" :key="detailIndex"/>
  </div>
</template>

<script>
import { getCosUrl } from '../utils';
export default {
    name: "member-detail",
    data() {
        return {
            detailIndex: null,
            detailUrl: ""
        };
    },
    "$store.state.device": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.detailUrl = getCosUrl(`/technology/memberDetail/${this.detailIndex}.webp`);
            }
        },
        deep: true
    },
    "$store.state.language": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.detailUrl = getCosUrl(`/technology/memberDetail/${this.detailIndex}.webp`);
            }
        },
        deep: true
    },
    mounted() {
        this.detailIndex = this.$route.params.index;
        this.detailUrl = getCosUrl(`/technology/memberDetail/${this.detailIndex}.webp`);
    },
    methods: {
        closeDetail() {
            this.$router.replace({
                name: 'Technology',
                params: {
                    isBack: true
                }
            });
        },
    },
};
</script>

<style lang="less">
div.member-detail {
    background: #0069ad;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 100%;
    @media screen and (min-device-width: 1200px) {
        min-height: 84rem;
        img {
            width: 100rem !important;
        }
    }
    @media screen and (max-device-width: 1199px) {
        flex-direction: column-reverse;
        min-height: 177.8667vw;
        img{
            margin: 13.3333vw auto 4.8vw;
            width: 89.3333vw!important;
        }
    }
    .btn-wrapper {
        display: flex;
        .el-button{
            padding: 0;
            img{
                width: 100% !important;
            }
        }
        @media screen and (min-device-width: 1200px) {
            justify-content: flex-end;
            margin-bottom: 4.2rem;
            width: 100rem;
            .el-button {
                height: 4.2rem;
                width: 4.2rem;
            }
        }
        @media screen and (max-device-width: 1199px) {
            justify-content: center;
            margin: 0 0 11.2vw 0;
            margin-bottom: 0;
            width: 100%;
            .el-button{
                height: 9.6vw;
                img{
                    margin: 0;
                }
                width: 9.6vw;
            }
        }
    }
}
</style>
